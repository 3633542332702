.ticketContain {
  height: 5em;
  float: left;
  position: relative;
  padding: 1em;
  margin-top: 1em;
  font-size: 0.7em;
}

.ticket-invalid-badge {
  float: left;
  position: relative;
  padding: 1em;
  margin-top: 1em;
  bottom: 15px;
  right: 15px;
  font-size: 14px;
}

.ticketLeft {
  background-image: url("../../static/couponImage/left.svg");
  background-repeat: no-repeat;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 9em;
  text-align: center;
  color: #007bff !important;
  ;
}

.ticketRight {
  background-image: url("../../static/couponImage/right.svg");
  background-repeat: no-repeat;
  width: 4.5em;
  color: white;
}

.finger {
  background-image: url("../../static/couponImage/finger.svg");
  background-repeat: no-repeat;
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  right: 0;
  bottom: 30%;
}

.grabag {
  background-image: url("../../static/couponImage/drawBag.svg");
  background-repeat: no-repeat;
  width: 3.5em;
  height: 3.5em;
  position: absolute;
  right: 0;
  bottom: 0;
}

.coupon-type {
  position: absolute;
  z-index: 10;
  left: 2em;
  top: 0;
}

.grabagImage {
  background-image: url("../../static/couponImage/drawBag.svg");
  background-repeat: no-repeat;
  width: 3.5em;
  height: 3.5em;
}

.coupon-check-modal {
  font-family: Verdana, sans-serif;
}

.coupon-check-modal-image {
  width: 300px;
  height: 300px;
  border-radius: 18px;
}

.coupon-check-modal td:first-child {
  color: #6c757d !important;
}

.coupon-list-card-container {
  width: 9.5rem;
  height: 9.5rem;
  border: 0;
  position: relative;
}

.coupon-list-card-img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.coupon-list-tag {
  position: absolute;
  top: 0.1rem;
  right: 0.6rem;
}

.coupon-list-name {
  position: absolute;
  bottom: 0.1rem;
  left: 0.3rem;
}