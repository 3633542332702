@media (max-width: 1999px) and (min-width: 577px) {
    .classification-icon {
        width: 230px;
        height: 230px;
    }
}

@media screen and (max-width: 576px) {
    .classification-icon {
        width: 180px;
        height: 180px;
    }
}

@media screen and (min-width: 1200px) {
    .classification-icon {
        width: 230px;
        height: 230px;
    }
}

.classification-icon-fill {
    filter: brightness(.5);
}