@media (max-width: 1999px) and (min-width: 577px) {
  .scroller:hover .scroll-controller {
    display: block;
  }
  .scroller::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .scroll-controller {
    display: none;
  }

  .shop-Thumbnail .img-container {
    width: 24vmin;
    height:0px;
    padding-bottom:100%;
    position:relative;
  }
}

@media screen and (max-width: 576px) {
  .scroll-controller {
    display: none;
  }
  .scroller::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  .shop-Thumbnail .img-container {
    width: 43vmin;
    height: 0px;
    padding-bottom: 100%;
    position: relative;
  }
}

@media screen and (min-width: 1200px) {
  .scroll-controller {
    display: none;
  }
  .scroller:hover .scroll-controller {
    display: block;
  }
  .scroller::-webkit-scrollbar {
    display: none;
  }

  .shop-Thumbnail .img-container {
    width: 21vmin;
    height: 0px;
    padding-bottom: 100%;
    position: relative;
  }
}

.scroller-item{
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.shopCard {
  /* max-width: 414px; */
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

.shop-Thumbnail img{
  width:100%;
  height:100%;
  position:absolute;
}

.productImage {
  max-width: 414px;
  max-height: 414px;
  width: 100vw;
  height: 100vw;
}

.ownCoupon-container {
  width: 250px;
  height: 80px;
  border-radius: 5px;
  box-shadow:  0 4px 8px 0 rgba(0,0,0,0.2);
}

.ownCoupon-content {
  width: 200px;
  height: 80px;
  border-radius: 5px;
  padding: 10px;
  box-shadow:  0 4px 8px 0 rgba(0,0,0,0.2);
}

.ownCoupon-image{
  width: 100px;
  height: 100px;
  border-radius: 15px;
}

.product_description_close {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.shop_popup_tag_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 30px;
  background: -webkit-linear-gradient(to right, #f8b500, #fceabb);  /* Chrome 10-25, Safari 5.1-6 */
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(1,1,6,1) 35%, rgba(17,80,93,1) 100%);
  text-align: center;
}

.storeInfo-details summary{
  list-style: none;
  background-color: #fff;
	position: relative;
	cursor: pointer;
	/* padding: 1em .5em; */
}
.storeInfo-details summary::-webkit-details-marker {
  display: none;
}

.collection-icon {
  width: 30px;
  height: 30px;
}