@media (max-width: 1999px) and (min-width: 577px) {
  .contact-container {
    /* background-color: #3e4551; */
    /* color: white; */
    width: 100%;
  }

}

@media screen and (max-width: 576px) {
  .contact-container {
    /* background-color: #3e4551; */
    /* color: white; */
    width: 100%;
    margin-bottom: 48px;
    /* margin-bottom: 50px; */
  }

  /* .foot-contact {
    margin-bottom: 50px;
  } */
}

@media screen and (min-width: 1200px) {
  .contact-container {
    /* background-color: #3e4551; */
    /* color: white; */
    width: 100%;
  }

}

.contact-container {
  height: 20vh;
  width: 100%;
}