@media (max-width: 1999px) and (min-width: 577px) {
  .nav-footer {
    display: none;
  }

  .notification-badge {
    position: absolute;
    right: 37%;
  }
}

@media screen and (max-width: 576px) {
  .nav-footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    /* height: 70px; */
  }

  .notification-badge {
    position: absolute;
    right: 15%;
  }
}

@media screen and (min-width: 1200px) {
  .nav-footer {
    display: none;
  }

  .notification-badge {
    position: absolute;
    right: 40%;
  }
}

.nav-footer {
  margin-bottom: 0px;
  border: 0px solid;
  border-color: #007bff;
  background-color: white;
  z-index: 1021;
}