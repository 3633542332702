@media (max-width: 1200px) and (min-width: 577px) {
    .root-container {
        /* margin-top: 150px; */
        padding-top: 150px;
    }

}

@media screen and (max-width: 576px) {
    .root-container {
        margin-top: 64px;
    }
}

@media screen and (min-width: 1200px) {
    .root-container {
        /* margin-top: 150px; */
        padding-top: 150px;
    }
}