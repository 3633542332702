@media (max-width: 1200px) and (min-width: 577px) {
    .search-result .product-img-container {
        width: 390px
    }

    .product-img {
        width: 390px;
        height: 390px;
        object-fit: cover;
    }

    .search-place-img {
        width: 150px;
        height: 150px;
        border-radius: 18px;
        background-color: gray;
    }

    .search-product-item {
        width: 300px;
        height: 360px;

    }

    .search-product-img {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 15px;
    }

}

@media screen and (max-width: 375px) {
    .search-result .product-img-container {
        width: 100%
    }

    .product-img {
        width: 100%;
        /* height: 360px; */
        
        max-width: 375px;
        max-height: 375px;
        object-fit: cover;
    }

    .search-place-img {
        width: 150px;
        height: 150px;
        border-radius: 18px;
        background-color: gray;
    }

    .search-product-item {
        /* width: 185px; */
        height: 300px;

    }

    .search-product-img {
        width: 160px;
        height: 160px;
        object-fit: cover;
        border-radius: 15px;
    }
}

@media screen and (max-width: 576px) and (min-width: 376px) {
    .search-result .product-img-container {
        width: 100%
    }

    .product-img {
        width: 100%;
        /* height: 360px; */
        
        max-width: 414px;
        max-height: 414px;
        object-fit: cover;
    }

    .search-place-img {
        width: 150px;
        height: 150px;
        border-radius: 18px;
        background-color: gray;
    }

    .search-product-item {
        /* width: 185px; */
        height: 300px;

    }

    .search-product-img {
        width: 180px;
        height: 180px;
        object-fit: cover;
        border-radius: 15px;
    }
}

@media screen and (min-width: 1200px) {
    .search-result .product-img-container {
        width: 390px
    }

    .product-img {
        width: 100%;
        /* height: 390px; */
        
        max-width: 419px;
        max-height: 419px;
        object-fit: cover;
    }

    .search-place-img {
        width: 150px;
        height: 150px;
        border-radius: 18px;
        background-color: gray;
    }

    .search-product-item {
        width: 300px;
        height: 360px;
    }

    .search-product-img {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 15px;
    }
}

/* 自訂禁用狀態的樣式 */
.spec-select option:disabled {
    color: #999;
    /* 設定灰色文字 */
    background-color: #eee;
    /* 設定淺灰色背景 */
    cursor: not-allowed;
    /* 設定不允許的游標樣式 */
}

.search-container {
    /* height: 200px; */
    border-radius: 18px;
    /* background: rgb(14, 10, 10);
    background: linear-gradient(188deg, rgba(14, 10, 10, 0.99781162464986) 0%, rgba(255, 255, 255, 1) 50%); */
}

.search-result-swiper-container {
    width: 100%;
    height: 420px
}

.search-recommend-card {
    color: black;
    font-weight: 700;
}

.search-text-gradient {
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.url-example-ripple-container {
    position: relative;
    overflow: hidden;
}

.url-example-ripple {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
    animation: ripple 1s ease-in-out infinite;
    right: -50%;
    top: 0
}

#search-input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    margin-left: -45px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/></svg>");
    cursor: pointer;
}

.two-line-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.product-info-detail img {
    width: 100%;
    height: auto;
}

.product-info-detail iframe {
    display: none;
}

.product-info-detail a[href^=https] {
    display: none;
}

/* .product-detail {
    border-radius: 10px;
    over-infoflow: hidden;
    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
    
}

.product-info-detail thead {
    background-color: #9b59b6;
    color: white;
}

.product-info-detail th, .product-detail td {
    border: none;
}

.prod-infouct-info-detail tr:not(:last-child) {
    border-bottom: 1px solid #eee;
}

.product-info-detail tbody tr:hover {
    background-color: #f5e8ff;
} */

.product-info-detail table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    margin-bottom: 30px;
}


.product-info-detail th, td {
    padding: 12px;
    text-align: left;
    border: none;
}

.product-info-detail th {
    background-color: #0091c9;
    color: white;
    font-weight: bold;
}

.product-info-detail tr:not(:last-child) td {
    border-bottom: 1px solid #eee;
}

.product-info-detail tbody tr:hover {
    background-color: #e6f3f9;
}

.product-info-detail ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.product-info-detail li {
    margin-bottom: 5px;
}

.product-info-detail a {
    color: #3498db;
    text-decoration: none;
}

.product-info-detail img {
    width: 100% !important;
}

.product-info-detail a:hover {
    text-decoration: underline;
}

.review-star {
    background: linear-gradient(to right, #ffd700, #ffa500);
    -webkit-background-clip: text;
    background-clip: text;
}

@keyframes ripple {
    from {
        height: 0;
        width: 0;
        opacity: 1;
    }

    to {
        width: 60%;
        height: 60%;
        opacity: 0;
    }
}