@media (max-width: 1200px) and (min-width: 577px) {

    .search-hor-swiper-container {
        width: 100%;
        height: 380px;
    }

    .search-hor-swiperitem {
        width: 250px;
        height: 250px;
    }

    .search-hor-swiper-container .swiper-button-prev {

        left: 58% !important;
        transform: translateX(-58%) !important;
    }

    .search-hor-swiper-container .swiper-button-next {
        left: 58% !important;
        transform: translateX(-58%) !important;
    }



}

@media screen and (max-width: 375px) {
    .search-hor-swiper-container {
        width: 100%;
        height: 280px;
    }

    .search-hor-swipeitem {
        width: 160px;
        height: 160px;
    }

    .search-hor-swiper-container .swiper-button-prev {

        left: 58% !important;
        transform: translateX(-58%) !important;
    }

    .search-hor-swiper-container .swiper-button-next {
        left: 58% !important;
        transform: translateX(-58%) !important;
    }
}

@media screen and (max-width: 576px) and (min-width: 376px) {
    .search-hor-swiper-container {
        width: 100%;
        height: 300px;
    }

    .search-hor-swipeitem {
        width: 180px;
        height: 180px;
    }

    .search-hor-swiper-container .swiper-button-prev {

        left: 58% !important;
        transform: translateX(-58%) !important;
    }

    .search-hor-swiper-container .swiper-button-next {
        left: 58% !important;
        transform: translateX(-58%) !important;
    }

    .search-swiper-prev-container .prev-btn {
        display: none;
    }

    .search-swiper-next-container .next-btn {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .search-hor-swiper-container {
        width: 100%;
        height: 380px;
    }

    .search-hor-swipeitem {
        width: 250px;
        height: 250px;
    }

    .search-hor-swiper-container .swiper-button-prev {
        left: 58% !important;
        transform: translateX(-58%) !important;
    }

    .search-hor-swiper-container .swiper-button-next {
        left: 58% !important;
        transform: translateX(-58%) !important;
    }
}

.search-swiper-next-container {
    position: absolute;
    top: 35%;
    right: 0%;
    transform: translateY(-50%);
    z-index: 3;
}

.search-swiper-prev-container {
    position: absolute;
    top: 35%;
    left: 0;
    transform: translateY(-50%);
    z-index: 3;
}

.search-swiper-prev-container .prev-btn {
    background-color: aliceblue;
    border-radius: 50%;
    opacity: .5;
}

.search-swiper-next-container .next-btn {
    background-color: aliceblue;
    border-radius: 50%;
    opacity: .5;
}