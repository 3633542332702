@media (max-width: 1200px) and (min-width: 577px) {
    .home-banner-item {
        width: 100vw;
        max-height: 480px;
        object-fit: cover;
    }

    .home-banner-mobile {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .home-banner-item {
        width: 100vw;
        max-height: 480px;
        object-fit: cover;
    }

    .home-banner-desktop {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .home-banner-item {
        width: 100vw;
        max-height: 480px;
        object-fit: cover;
    }

    .home-banner-mobile {
        display: none;
    }

}

.gift-req-tutorial-title {
    font-size: 36px;
    font-weight: 700;
    color: #0091c9;
}

.gift-req-tutorial-content {
    font-size: 18px;
    font-weight: bold;
    /* text-shadow: 0 0 25px black; */
}

blockquote:before {
    content: '“';
}

blockquote:after {
    content: '”';
}