html, body, #app, #app>div {
  height: 100%
}

body{
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.coupon {
  display: inline-block;
  color: white;
  /* padding: 40px 50px 20px; */
  padding: 10px;
  background: linear-gradient(90deg,
      white 0%,
      white 20%,
      #0091c9 20%,
      #0091c9 70%);
  border-radius: 10px 10px 10px 10px;
}

.notifyBadge {
  top: -0.5em;
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.thin-scroll::-webkit-scrollbar {
  width: 2px;
}

.section::-webkit-scrollbar-track {
  background-color: darkgrey;
}

.general-details {
  border-top: 1px solid darkgrey;
  /* border-bottom: 1px solid darkgrey; */
}

.general-details summary {
  list-style: none;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  padding: 1em .5em;
}

.general-details summary::-webkit-details-marker {
  display: none;
}

.large-total-price {
  font-size: 32px;
  font-weight: 900;
}

.float-container {
  position: fixed;
  bottom: 65px;
  right: 5%;
  z-index: 52;
}

.hr-text {
  /* border: 0;
  font-size: 14px;
  height: 1.5em;
  line-height: 1em;
  position: relative;
  text-align: center; */
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;

  &:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;
  }
}