
.carousel {
    overflow: hidden;
    width: 100%; /* Adjust to the width of the container */
    scroll-snap-type: mandatory;
  }
  
  .carousel-track {
    display: flex;
    animation: scroll 20s linear infinite;
  }
  
  .carousel-track img {
    width: 300px; /* Adjust based on how many images you want visible at a time */
    flex-shrink: 0;
  }
  
  /* @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  } */