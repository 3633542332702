.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .about-container h1 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 40px;
  }
  
  .about-container h2 {
    color: #3498db;
    margin-top: 30px;
  }
  
  .story-section, .mission-section, .journey-section, .vision-section {
    margin-bottom: 40px;
    font-weight: bold;
  }
  
  .mission-icons {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
  }
  
  .icon-item {
    text-align: center;
  }
  
  .icon {
    font-size: 48px;
    color: #3498db;
    margin-bottom: 10px;
  }
  
  .about-container p {
    margin-bottom: 15px;
  }
  
  @media (max-width: 600px) {
    .mission-icons {
      flex-direction: column;
    }
    
    .icon-item {
      margin-bottom: 20px;
    }
  }