.swiper_container {
  position: relative;
}

.gift-swiper-item {
  width: 24rem;
  /* height: 36rem; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 1rem 0;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.pick-order {
  border: solid 3px #0091c9;
  border-radius: 12px;
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.gift-card {
  /* width: 18rem; */
  /* width: 95%; */
  border-radius: 18px;
  margin: 8rem 0 5rem 0;
}

.gift-card-img {
  width: 16rem;
  height: 16rem;
  border-radius: 2rem;
  object-fit: cover;
  margin-top: -8rem;
}

.gift-card-content {
  /* height: 11rem; */
  /* If auto or scroll will crash horizontal scroll */
  overflow: hidden;
}

.gift-res-back-step-arrow {
  color: #0091c9;
  cursor: pointer;
  font-weight: bold;
  /* width: auto; */
}

/* .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
  }
  
  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
  } */
.bottom-modal .modal-dialog {
  position: fixed;
  margin: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .bottom-modal .modal-dialog {
    width: 80%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

.bottom-modal .modal-content {
  border-radius: 0;
  border: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bottom-modal .modal-body {
  /* padding-bottom: env(safe-area-inset-bottom); */
}

@media (max-width: 576px) {
  .bottom-modal .modal-dialog {
    margin: 0;
  }
}

.bottom-modal .modal-dialog {
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.bottom-modal.show .modal-dialog {
  transform: translateY(0);
}

.shine-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: skewX(-20deg);
  animation: shine 1s infinite;
}

@keyframes shine {
  0% {
    transform: skewX(-20deg) translateX(-100%);
  }
  100% {
    transform: skewX(-20deg) translateX(200%);
  }
}