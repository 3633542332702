@media (max-width: 1200px) and (min-width: 577px) {
  .header {
    /* height: 100px; */
    display: none;
  }

  .header-lg {
    background-color: white;
    /* margin-bottom: 30px; */
  }

}

@media screen and (max-width: 576px) {
  .header {
    /* height: 95px; */
    background-color: white;
    /* margin-bottom: 12px; */
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .header-lg {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .header {
    display: none;
  }

  .header-lg {
    background-color: white;
    /* margin-bottom: 50px; */
  }
}

.recommend-category-title {
  font-size: 14px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
}

.recommend-category-title:hover {
  font-weight: 900;
  border-bottom: 3px solid;
}

.recommend-keyword {
  font-size: 15px;
  line-height: 30px;
  cursor: pointer;
  text-decoration: none;
  color: rgb(34, 34, 34);
}

.recommend-keyword:hover {
  font-weight: 900;
  color: rgb(34, 34, 34);
}