@media (max-width: 1999px) and (min-width: 577px) {
    .option_grid_container {
        /* width: 300px; */
        height: 175px;
        background-color: #E6E6E6;
    }
}

@media screen and (max-width: 576px) {
    .option_grid_container {
        /* width: 100%; */
        height: 150px;
        background-color: #E6E6E6;
    }
}

@media screen and (min-width: 1200px) {
    .option_grid_container {
        /* width: 200px; */
        height: 200px;
        background-color: #E6E6E6;
    }
}

.tutorial-container {
    position: relative;
}

.tutorial-img {
    object-fit: cover;
    width: 100%;
    max-height: 100px;
    filter: contrast(1.35) brightness(0.65);
    border-radius: 15px;
}

.tutorial-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0 0 25px black;
    font-size: 20px;
}