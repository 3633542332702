.del-price {
    text-decoration: line-through;
}

.required::before {
    content: "*";
    color: red;
}

.address-dropdown {
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 350px;
    color: #333;
    padding: 0;
    margin: 0;
    background-color: #fff;
    box-shadow: 0px 2px 3px 0px #ccc;
    border-radius: 6px;
    box-sizing: border-box;
    overflow: auto;
    z-index: 10;
}

.address-dropdown>li {
    display: block;
    color: #000;
    padding: 0.75rem;
    font-size: 14px;
    margin: 0 10px;
    cursor: pointer;
    border-bottom: #ccc solid 1px;
}

.custom-tabs .nav-link {
    color: black;
}

.custom-tabs .nav-link.active {
    color: #2997FF;
    border-color: transparent;
    border-bottom: 2px solid #2997FF;
}

.custom-tabs .nav-link:not(.active) {
    background-color: transparent;
}