.dropdown-toggle:after { content: none }

@media (max-width: 1999px) and (min-width: 577px) {
    .location-expanded-container {
        background-color: white;
        border-radius: 15px;
        z-index: 1;
        width: 450px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .location-expanded-container {
        background-color: white;
        border-radius: 15px;
        z-index: 1;
        /* min-width: 100%; */
    }
  }
  
  @media screen and (min-width: 1200px) {
    .location-expanded-container {
        /* background-color: white; */
        border-radius: 15px;
        z-index: 1;
        width: 450px;
    }
  }
.location-dropdown-container {
  position: relative;
  max-height: 200px;
}
.location-dropdown{
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 350px; 
  color: #333;
  padding: 0;
  margin: 0;
  background-color: #fff;
  box-shadow: 0px 2px 3px 0px #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: auto; 
  z-index: 10;
}
.location-dropdown > li {
  display: block;
  color: #000;
  padding: 0.75rem;
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
  border-bottom: #ccc solid 1px;
}