.step-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .step {
    flex: 1;
    text-align: center;
    position: relative;
  }
  
  .step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 15px;
    left: 50%;
    right: -50%;
    height: 2px;
    background-color: #e0e0e0;
    z-index: -1;
  }
  
  .step.completed:not(:last-child)::after {
    background-color: #4caf50;
  }
  
  .step-indicator {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 5px;
  }
  
  .step.completed .step-indicator,
  .step.active .step-indicator {
    background-color: #4caf50;
  }
  
  .step-label {
    font-size: 14px;
    color: #333;
  }
  
  .step.active .step-label {
    font-weight: bold;
  }