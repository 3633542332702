.message-self{
    background-color: #0091c9;
    color: white;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
}

.message-other{
    background-color: #efefef;
    color: #646464;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin:80px 0 5px 0;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: rgb(219,219,219);
}